import React from 'react';

const RangeSlider = () => {
  return (
    <div>
      <h3>Skills</h3>
      <div>
        <p>Investment Trusts</p>
        <div style={{
          width: '100%',
          height: '7px',
          borderRadius: '3px',
          backgroundColor: '#f0f0f0',
          position: 'relative',
          marginBottom: '10px',
        }}>
          <div style={{
            width: '90%',
            height: '7px',
            borderRadius: '3px',
            backgroundColor: '#F00000',
            position: 'absolute',
            top: 0,
            left: 0,
          }} />
          <p style={{
            position: 'absolute',
            top: '-20px',
            right: 0,
          }}>90%</p>
        </div>
      </div>
      <div>
        <p>Trades & Stocks</p>
        <div style={{
          width: '100%',
          height: '7px',
          borderRadius: '3px',
          backgroundColor: '#f0f0f0',
          position: 'relative',
          marginBottom: '10px',
        }}>
          <div style={{
            width: '80%',
            height: '7px',
          borderRadius: '3px',
            backgroundColor: '#F00000',
            position: 'absolute',
            top: 0,
            left: 0,
          }} />
          <p style={{
            position: 'absolute',
            top: '-20px',
            right: 0,
          }}>80%</p>
        </div>
      </div>
      <div>
        <p>Financial Planning</p>
        <div style={{
          width: '100%',
          height: '7px',
          borderRadius: '3px',
          backgroundColor: '#f0f0f0',
          position: 'relative',
          marginBottom: '10px',
        }}>
          <div style={{
            width: '70%',
            height: '7px',
          borderRadius: '3px',
            backgroundColor: '#F00000',
            position: 'absolute',
            top: 0,
            left: 0,
          }} />
          <p style={{
            position: 'absolute',
            top: '-20px',
            right: 0,
          }}>70%</p>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;