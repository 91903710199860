import React from 'react'
import Header from './Header/header'
import Footer from './Footer/Footer'

const Layout = (props) => {
  return (
    <div>
      <Header/>
      <main style={{ minHeight: '85vh' }}>
             
             { props.children }
           
         </main>
     <Footer/>
    </div>
  )
}

export default Layout
