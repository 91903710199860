import React from 'react';

const OurProcess = () => {
  return (
      <main>
        <section>
        <div className="title mt-5">
          <h2 className='product'>Our Process</h2>
          <div className="underline"></div>
        </div>
        <div className='container'>
          <div className='row '>
            <div className='col-md-3'>
            <h4 className="step-title font-semibold">01.<br/><br/> Research</h4>
            <p className="step-description">"We invest in research and development to continuously enhance our products and stay ahead of industry trends."</p>
            </div>
            <div className='col-md-3'>
            <h4 className="step-title font-semibold">02.<br/><br/> Idea & Concept</h4>
          <p className="step-description">"We focus on innovative ideas and creative concepts to deliver unique and effective solutions across all our product ranges."</p>
            </div>
            <div className='col-md-3'>
            <h4 className="step-title font-semibold ">03.<br/><br/> Design & Production</h4>
          <p className="step-description">"Our design and production processes combine creativity with precision to deliver high-quality products that meet our customers' needs and expectations."</p>
            </div>
            <div className='col-md-3'>
            <h4 className="step-title font-semibold">04.<br/><br/> Sales & Service</h4>
            <p className="step-description">"Our sales and service teams are dedicated to providing exceptional support and personalized solutions, ensuring a seamless and satisfying experience for every customer."</p>
            </div>
          </div>
        </div>
        </section>
      </main>
  
    
  );
};

export default OurProcess;