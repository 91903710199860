import React from 'react'
import '../Header/header.css'
import { Link } from 'react-router-dom'

const Header = () => {

  return (
    <div>
     <header class="header">
    <div class="top-bar">
      <div class="top-bar__content">
        <section class="phone">
          <i class="fa-solid fa-phone icon"></i>
          <span>+0086 134560012967</span>
        </section>
        <section class="email">
          <i class="fa-solid fa-envelope icon"></i>
          <span>sales.baozon@baozonghaolimited.com</span>
        </section>
      </div>
    </div>

    <div className="header_bottom">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
            <div className='container-fluid bottom-bar__content'>
          <Link to="/" class="logo">
          <img class="logo__img" src="./assets/logo1.png" alt="logo"/>
          <span class="logo__text">BAO ZONG HAO LIMITED</span>
        </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ">
            <li className="nav-item" >
                <Link className="nav-link" to="/"> Home</Link>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/product">Products </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/job">Career </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link btn" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
    </div>
  )
}

export default Header
