import React from 'react'
import Layout from '../Components/Layout'
import Caraousal from '../Components/Productcontent/Caraousal'
import Slider1 from './../Components/Productcontent/Slider';


const Homepage = () => {

  const Carousal1 = {
    img1: "./assets/Paper/9.png",
    img2: "./assets/Paper/2.png",
    img3: "./assets/Paper/3.png",
    img4: "./assets/Paper/4.png",
    img5: "./assets/Paper/5.png",
    img6: "./assets/Paper/6.png",
    img7: "./assets/Paper/7.png",
    img8: "./assets/Paper/8.png",
    img9: "./assets/Paper/1.png",
  }

  const carasoule2 = {
    img1: "./assets/cosmetics/Eye shadow.png",
    img2: "./assets/cosmetics/main/1.png",
    img3: "./assets/cosmetics/main/2.png",
    img4:"./assets/cosmetics/main/3.png",
    img5: "./assets/cosmetics/main/6.png",
    img6: "./assets/cosmetics/main/5.png",
    img7: "./assets/cosmetics/main/7.png",
    img8: "./assets/cosmetics/main/8.png",
    img9: "./assets/cosmetics/main/9.png",
  }
  const carasoule3 = {
    img1: "./assets/Disposable/main/1.png",
    img2:"./assets/Disposable/main/5.png",
    img3: "./assets/Disposable/main/7.png",
    img4: "./assets/Disposable/main/10.png",
    img5: "./assets/Disposable/main/13.png",
    img6: "./assets/Disposable/main/14.png",
    img7: "./assets/Disposable/main/15.png",
    img8: "./assets/Disposable/main/11.png",
    img9:"./assets/Disposable/main/4.png",
  }
  const carasoule4 = {
    img1: "./assets/Garments/main/1.png",
    img2:"./assets/Garments/main/3.png",
    img3:"./assets/Garments/main/5.png",
    img4: "./assets/Garments/main/7.png",
    img5: "./assets/Garments/main/14.png",
    img6: "./assets/Garments/main/15.png",
    img7: "./assets/Garments/main/12.png",
    img8: "./assets/Garments/main/8.png",
    img9: "./assets/Garments/main/2.png",
  }
  const carasoule5 = {
    img1: "./assets/Stationery/main/1.png",
    img2: "./assets/Stationery/main/3.png",
    img3: "./assets/Stationery/main/4.png",
    img4: "./assets/Stationery/main/6.png",
    img5: "./assets/Stationery/main/8.png",
    img6: "./assets/Stationery/main/7.png",
    img7: "./assets/Stationery/main/13.png",
    img8: "./assets/Stationery/main/15.png",
    img9: "./assets/Stationery/main/5.png",
  }
  return (
    <Layout>
      <div className='container-fluid  ' style={{ backgroundColor: "#000" }} >
        <div className='row '>
          <div className='container'>
            <div className='col-md-12 text-center ' style={{ marginTop: "10px" }}>
              <Caraousal />
            </div>
          </div>
        </div>
      </div>

      {/* about section start here */}

      <div className='container mission ' >
        <div className='row '>
          <div className='col-md-6'>
          <img src='./assets/Mission.png' style={{width:"500px",height:"500px"}} alt='mission'/>
          </div>
          <div className='col-md-6 '>
            <h4 className='mt-3' style={{ color: "#F00000", fontFamily: "Time New Roman", fontWeight: "bold" }}>Welcome to BAO ZHONG HAO LIMITED</h4>
            <h2 style={{ fontFamily: "Time New Roman" }}>Our mission is to
              make your life easier.</h2>
            <p style={{ wordSpacing: "5px", fontSize: "17px" }}>In our paper disposable and cosmetics business, our mission is to redefine sustainability in the beauty industry. We are committed to offering innovative and eco-friendly paper-based disposable products that cater to the needs of modern consumers while minimizing environmental impact. Our vision extends beyond traditional beauty practices, as we aim to revolutionize the way disposable cosmetics are perceived and utilized.</p>
            <div className='row '>

              <div className='col-md-6'>
                <ul style={{ wordSpacing: "5px", fontSize: "17px" }}>
                  <li>Wide Range</li>
                  <li>User-Friendly Interface</li>
                  <li>Commitment to Excellence</li>
                  <li>
                    Simple and Elegent Design
                  </li>
                </ul>
              </div>
              <div className='col-md-6  py-5 text-center' style={{ borderRadius: "50%", border: "1px solid #F00000" }}>
                <h3 style={{ color: "#F00000", fontFamily: "Time New Roman", fontWeight: "bold" }}>100% Good Quality</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about section end here */}
      <div className='container'>
        <div className='row '>
          <div className='col-md-12 pro'>
            <h3 className='product'>Top Sellers in Paper Products for You</h3>
            <Slider1 {...Carousal1} />

          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row '>
          <div className='col-md-12 pro'>
            <h3 className='product'>Best Sellers in Cosmetics Products for You</h3>
            <Slider1 {...carasoule2} />

          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row '>
          <div className='col-md-12 pro'>
            <h3 className='product'>Best Sellers in Disposable Products for You</h3>
            <Slider1 {...carasoule3} />

          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row '>
          <div className='col-md-12 pro'>
            <h3 className='product'>Top Sellers in Garments for You</h3>
            <Slider1 {...carasoule4} />

          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row '>
          <div className='col-md-12 pro'>
            <h3 className='product'>Best Sellers in Stationary Products for You</h3>
            <Slider1 {...carasoule5} />

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Homepage
