
import './App.css';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';

import Homepage from './Pages/Homepage';
import Product from './Pages/Product';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import JobData from './Data/jobdata';
import Careerdetail from './Pages/Careerdetail';
import Form  from './Pages/Form';


function App() {
  return (
    <>
    <Router>
        <Routes>
            <Route path='/'element={<Homepage/>}/>
            <Route path='product'element={<Product/>}/>
            <Route path='about'element={<About/>}/>
            <Route path='contact'element={<Contact/>}/>
            <Route path='job'element={<Career/>}/>
            {JobData.map((ele)=>(
             <Route
             key={ele.id}
             path={`/jobPlace${ele.id}`}
             element={<Careerdetail  title={ele.title} 
             des={ele.description} JobType={ele.jobType} sal={ele.salary} proType={ele.projectType} location={ele.location}  company={ele.company} skills={ele.skills}
             
             />}
            />))}
             <Route path='/jobForm/:title' element={<Form/>}/>
        </Routes>
       
    </Router>
    
    </>
  );
}

export default App;

