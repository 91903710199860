import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider1 = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <div className="slider-container container">
      <Slider {...settings}>
        <div className='card'>
          <img src={props.img1} id='img' alt='' />
        </div>
        <div className='card'>
          <img src={props.img2} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img3} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img4} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img5} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img6} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img7} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img8} id='img' alt=''/>
        </div>
        <div className='card'>
          <img src={props.img9} id='img' alt=''/>
        </div>
      </Slider>
    </div>
  )
}

export default Slider1
