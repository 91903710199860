import React from "react";
import { Link } from "react-router-dom";
import './jobcard.css'
import JobData from "../../Data/jobdata";

function Job() {


  return (
    <div className='container-fluid'>
    <div className='container jobcard'>
        <div className='row  d-flex justify-between items-center  '>
        {JobData.map((i) => (
        <div key={i.id}>
        
            <div className=" inner items-start d-grid mb-2">
              <div className="card-body innerjob">
              <h1 className='fs-3 fw-bold ms-4'> {i.title} ~ {i.company}</h1>
              <p className='fs-5 ms-4'>{i.jobType} &#x2022; {i.experience} &#x2022; {i.projectType}</p>
              <h5 className='  text-center ms-4' style={{width:"150px", backgroundColor:"#ccc"}}>{i.salary}</h5>
              
            <div className='d-flex   '>
                <h4 className="ms-4 font-semibold">Skills:</h4>
                <p><br/>  
                 {/* {product.skills} */}
                {`${i.skills.slice(0,10)}...`}
                </p>
            </div>
              
                <p className="card-text ms-4">{`${i.description.slice(0, 200)}...`}</p>
                <Link to={i.Link} className="btn btn-primary read_more_btn ms-4">Apply Now</Link>
              </div>
            </div>
          
        </div>
      ))}
        </div>



        <br/>
       
    </div>
    </div>
   
  );
}

export default Job;
