import React from 'react'
import Layout from '../Components/Layout'
import RangeSlider from '../Components/Productcontent/RangeSlider'
import OurProcess from '../Components/Productcontent/Process'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <Layout>
      <div>
      <img src='./assets/1.png' alt='About us' style={{width:"1500px"}} />
      </div>
<div className='container-fluid'>
    <div className='row'>

      <nav className='breadcrumb' aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link className='crumb' to="/">Home</Link></li>
    <li className="breadcrumb-item active" aria-current="page">About</li>
  </ol>
</nav>
    </div>
</div>

<div className='container mt-5'>
    <div className='row'>
        <div className='col-md-6'>
            <img src='./assets/AboutUs.png' alt='Mission'/>

        </div>
        <div className='col-md-6 m-auto'>
            <h3>About Us </h3>
            <p>
            The Bao Zong Hao Limited website features a diverse range of products, including paper, cosmetics, garments, stationery, and disposables. It serves as a comprehensive platform for showcasing their offerings, catering to both individual and business needs with a focus on quality and variety.
            </p>
            <img src='./assets/About.png' alt='About'/>

        </div>
    </div>
</div>


{/* //why us */}


<section className="text-gray-600 body-font ">
  <div className="container px-5 pb-24 mx-auto flex flex-wrap">
        <div >
            <h3>Who We Are</h3>
            <p>"We are Bao Zong Hao Limited, a company dedicated to providing a wide range of high-quality products including paper, cosmetics, garments, stationery, and disposables. Our mission is to deliver exceptional products that meet the diverse needs of our customers, while maintaining a commitment to excellence and innovation in every sector we serve."</p>
            <h3>Why Us</h3>

        </div>
    <div className="flex flex-wrap w-full">
      <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
        <div className="flex relative ">
            
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
           
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Wide Range of  Products</h2>
            <p className="leading-relaxed">"We offer a wide range of products to meet all your needs, from paper and cosmetics to garments, stationery, and disposables."</p>
          </div>
        </div>
        <div className="flex relative">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            {/* <div className="h-full w-1 bg-gray-200 pointer-events-none" /> */}
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">User-Friendly Interface</h2>
            <p className="leading-relaxed">"Our website features a user-friendly interface, making it easy to browse and find the products you need quickly and efficiently."</p>
          </div>
        </div>
        <div className="flex relative ">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Commitment to Excellence</h2>
            <p className="leading-relaxed">"We are dedicated to a commitment to excellence, ensuring that every product we offer meets the highest standards of quality and performance."</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
           
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Simple and Elegant Design</h2>
            <p className="leading-relaxed">"Our products feature a simple and elegant design, combining functionality with a refined aesthetic to enhance both everyday use and special occasions."</p>
          </div>
        </div>       
      </div>
      <img className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12 imgbox" src="./assets/company.jpg" alt="step"  style={{height:"470px"}}/>
    </div>
  </div>
</section>
{/* end here */}

<div className='container'>
    <div className='row'>
        <div className='col-md-6 '>
            <h3>What We Can Do</h3>
            
            <p className='v1 pl-4'>At Bao Zong Hao Limited, we offer a comprehensive range of high-quality products designed to meet diverse needs. Whether you're looking for premium paper, luxurious cosmetics, stylish garments, functional stationery, or practical disposables, we have you covered. Explore our offerings to find solutions that perfectly fit your requirements.</p>

            <p className='line'>Our user-friendly website ensures a seamless shopping experience, while our commitment to excellence guarantees top-notch products and customer satisfaction. "We continuously innovate to provide exceptional products and services, ensuring that every experience with us exceeds your expectations."</p>
             
        </div>
        <div className='col-md-6'>
            <RangeSlider/>
        </div>
    </div>
</div>

{/* process start here  */}

<OurProcess/>
{/* process end here */}

    </Layout>
  )
}

export default About
