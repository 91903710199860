const menu = [
  {
    id: 1,
    title: 'Mask',
    category: 'Disposable',
    price: 1200,
    img:
      './assets/Disposable/8.png',
    desc: `A mask is a protective covering worn over the face, used for various purposes including health, safety, and cosmetic applications. It can help prevent the spread of illness, filter air, or serve as a fashion accessory.`,
  }, 
    {
      id: 2,
      title: 'Face wash',
      category: 'Cosmetics',
      price: 1700,
      img:
       './assets/cosmetics/face wash.png',
      desc: `A gentle facewash helps cleanse the skin by removing dirt, oil, and impurities without stripping moisture. Regular use of facewash can promote clear, refreshed skin and prevent breakouts. `,
    },
    {
      id: 3,
      title: 'Bussiness Stationary',
      category: 'Stationary',
      price: 1000,
      img: './assets/Stationery/2.png',
      desc: `Business stationery includes essential items like letterheads, business cards, and envelopes that reflect a company's brand identity. It provides a professional and cohesive appearance for official correspondence and marketing materials.`,
    },
    {
      id: 4,
      title: 'Carbon Paper',
      category: 'Paper',
      price: 1000,
      img: './assets/Paper/1.png',
      desc: `Carbon paper is a thin, coated sheet used to create duplicate copies of handwritten or typed documents. By placing it between two sheets of paper, it transfers the original text onto the second sheet through pressure.`,
    },
    {
      id: 5,
      title: 'Glass',
      category: 'Disposable',
      price: 1200,
      img:
        './assets/Disposable/4.png',
      desc: `Glass is a transparent, rigid material made from silica and other compounds, commonly used for windows, containers, and decorative items. Its clarity and versatility make it ideal for various applications, from functional uses to artistic creations. `,
    }, 
    {
      id: 6,
      title: 'Hair color',
      category: 'Cosmetics',
      price: 1200,
      img:
       './assets/cosmetics/hair color.png',
      desc: `Hair color can transform your look, adding vibrancy and enhancing your natural beauty with a range of shades from subtle highlights to bold hues. `,
    },
    {
      id: 7,
      title: 'Calculator',
      category: 'Stationary',
      price: 2500,
      img:
        './assets/Stationery/1.png',
      desc: `A calculator performs mathematical operations quickly and accurately, ranging from basic arithmetic to complex functions. It’s a practical tool for solving problems and managing calculations efficiently. `,
    },
    {
      id: 8,
      title: 'Carbonless',
      category: 'Paper',
      price: 2200,
      img:
        './assets/Paper/14.png',
      desc: `Carbonless paper, also known as NCR (No Carbon Required) paper, allows for multiple copies of a document without the need for carbon paper. It uses a special coating to transfer the impression from the top sheet to the sheets below, producing duplicate or triplicate copies simultaneously.`,
    },
    {
      id: 9,
      title: 'Ice',
      category: 'Disposable',
      price: 1200,
      img:
        './assets/Disposable/Ice.png',
      desc: `Ice is frozen water, typically used to cool drinks and preserve food. It forms as a solid when water reaches temperatures below 32°F (0°C) and can be found in various shapes, such as cubes, crushed, or blocks, depending on its intended use. `,
    }, 
    {
      id: 10,
      title: 'Ponds (Facial Form)',
      category: 'Cosmetics',
      price: 2200,
      img:
        './assets/cosmetics/Cosmetics/7.png',
      desc: `POND'S offers a variety of skincare solutions, including the POND'S Pure Bright range for radiant, even-toned skin, and the POND'S Acne Solution line to target and prevent breakouts`,
    },
    {
      id: 11,
      title: 'Hair Removal Spray',
      category: 'Cosmetics',
      price: 2500,
      img:
        './assets/cosmetics/Hair removal spray.png',
      desc: `Hair removal sprays provide a quick and painless solution to eliminate unwanted hair, leaving skin smooth and hair-free in minutes. `,
    },
    {
      id: 12,
      title: 'Shirts(men)',
      category: 'Garments',
      price: 1150,
      img:'./assets/Garments/9.png',
      desc: `Men's shirts come in a variety of styles, from casual to formal, offering versatile options for every occasion. Quality fabrics and well-tailored fits make men's shirts a wardrobe essential, combining comfort and style.`,
    },
    {
      id: 13,
      title: 'Graph Paper',
      category: 'Paper',
      price: 1000,
      img: './assets/Paper/12.png',
      desc: `Graph paper features a grid of evenly spaced lines, creating squares that help with drawing precise graphs, charts, and diagrams. It’s commonly used for mathematical plotting, engineering designs, and creating structured layouts.`,
    },
    {
        id: 14,
        title: 'Pant Coat(men)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/1.png',
        desc: `A men's pant coat, or suit, is a classic ensemble that exudes sophistication and is perfect for formal events or business settings. Tailored to perfection, pant coats offer a sharp, polished look, available in a variety of fabrics and cuts to suit different styles and occasions.`,
      },
      {
        id: 15,
        title: 'Plates',
        category: 'Disposable',
        price: 1700,
        img:
          './assets/Disposable/1.png',
        desc: `Plates are flat, typically round dishes used for serving and eating food. They come in various materials like ceramic, glass, plastic, and metal, and are designed for durability and ease of use during meals. `,
      },
     
      {
        id: 16,
        title: 'Relvon Clsk',
        category: 'Cosmetics',
        price: 1200,
        img:
         './assets/cosmetics/Cosmetics/1.png',
        desc: `REVLON CLSK offers a range of rich, vibrant hair color shades to suit various preferences. The available shades include CLSK-41 Medium Brown for a natural look, CLSK-10 Black for a bold and deep color, CLSK-51 Light Brown for a soft, subtle hue, CLSK-20 Brown Black for a balanced mix of tones, and CLSK-30 Dark Brown for a deeper, warm shade. `,
      },
      {
        id: 17,
        title: 'Clip',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Clip.png',
        desc: `A clip secures and holds papers or other items together, keeping them organized and easily accessible. It comes in various sizes and types, such as paper clips or binder clips, to suit different needs. `,
      },
      {
        id: 18,
        title: 'Tea',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/Tea.png',
        desc: `Tea disposables, such as tea bags and infusers, offer a convenient way to brew and enjoy tea without the need for traditional teapots or strainers. They provide an easy, mess-free solution for steeping single servings of tea.`,
      },
      {
        id: 19,
        title: 'Chalk Paper',
        category: 'Paper',
        price: 2200,
        img:
          './assets/Paper/13.png',
        desc: `Chalk paper is a type of paper with a textured surface designed to mimic the feel of a chalkboard. It is often used for drawing or writing with chalk or chalk-like markers, providing a reusable surface for creative projects or teaching purposes.`,
      },
      {
        id: 20,
        title: 'Nevia Men',
        category: 'Cosmetics',
        price: 2200,
        img:
         './assets/cosmetics/Cosmetics/15.png',
        desc: `NIVEA MEN offers a wide range of deodorants designed for long-lasting protection and freshness. Options include Black & White Invisible,, Deep Black Charcoal, Cool Kick Extra Day, Dry Comfort and Dry Impact and Fresh Energy for a revitalizing burst.  `,
      },
      {
        id: 21,
        title: 'Construction Paper',
        category: 'Paper',
        price: 2500,
        img:
          './assets/Paper/11.png',
        desc: `Construction paper is a heavy, colored paper used for arts and crafts projects. It comes in a variety of vibrant colors and is known for its durability, making it ideal for creating collages, cutouts, and other creative projects. `,
      },
      {
        id: 22,
        title: 'Colored Folder',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/3.png',
        desc: `A colored folder helps organize and categorize documents by using different colors for easy identification. It adds a visual element to filing systems, making it simple to find and access specific papers quickly.`,
      },
      {
        id: 23,
        title: 'Jeans(ladies)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/12.png',
        desc: `Ladies' jeans come in a variety of cuts and styles, from skinny and straight to relaxed and high-waisted, offering versatility for casual and chic looks. Designed for comfort and style, women's jeans are available in different washes, colors, and stretch fabrics to flatter various body types and suit any occasion`,
      },
      {
        id: 24,
        title: 'Eye Shadow',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Eye shadow.png',
        desc: `Eyeshadow adds depth and dimension to the eyes, enhancing their shape and color. Available in various shades and finishes, it allows for endless creativity, from subtle, everyday looks to bold, dramatic styles. `,
      },
      {
        id: 25,
        title: 'Cuter',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Cuter.png',
        desc: `A cutter, or paper cutter, is a tool used to trim or cut paper and other materials with precision. It helps achieve clean, straight cuts, making it ideal for crafting, office work, and various projects. `,
      },
      {
        id: 26,
        title: 'Clay face wash',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Clay face wash.png',
        desc: `Clay face wash helps detoxify the skin by drawing out impurities, excess oil, and dirt, making it ideal for oily and combination skin types. `,
      },
      {
        id: 27,
        title: 'Kraft Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/7.png',
        desc: `Kraft paper is a strong, brown paper made from unbleached wood pulp. It is known for its durability and natural, rustic appearance, and is commonly used for packaging, wrapping, and crafting.`,
      },
      {
        id: 28,
        title: 'Cream face wash',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Cream face wash.png',
        desc: `Cream face wash gently cleanses while providing deep hydration, making it perfect for dry and sensitive skin types. Its creamy texture helps maintain the skin's natural moisture barrier, leaving it soft, smooth, and nourished after every wash. `,
      },
      {
        id: 29,
        title: 'Foam face wash',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Foam face wash.png',
        desc: `Foam face wash offers a light and airy texture that effectively removes dirt, oil, and makeup, leaving the skin feeling refreshed and deeply cleansed. `,
      },
      {
        id: 30,
        title: 'Micellar cleanser',
        category: 'Cosmetics',
        price: 2200,
        img:
         './assets/cosmetics/Micellar cleanser.png',
        desc: `Micellar cleansers use tiny micelle molecules to gently lift away dirt, makeup, and impurities without the need for rinsing, making them perfect for all skin types. `,
      },
      {
        id: 31,
        title: 'Printing Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/4.png',
        desc: `Printing paper is designed specifically for use in printers and copiers, offering a smooth surface for clear, sharp text and images. It typically comes in various weights and finishes, such as matte or glossy, to suit different printing needs and applications.`,
      },
      {
        id: 32,
        title: 'Envelopes',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/4.png',
        desc: `Envelopes are used to enclose and protect letters, documents, and other correspondence. They come in various sizes and styles, and often feature adhesive flaps for sealing, making them essential for mailing and organization. `,
      },
      {
        id: 33,
        title: 'Gel face wash',
        category: 'Cosmetics',
        price: 2200,
        img:
         './assets/cosmetics/Gel face wash.png',
        desc: `Gel face wash provides a refreshing and lightweight cleanse, effectively removing impurities and excess oil without over-drying the skin. Formulated with hydrating ingredients, gel face wash is ideal for oily and combination skin types, leaving the complexion clean, smooth, and revitalized.`,
      },
      {
        id: 34,
        title: 'Beauty Kit',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/4.png',
        desc: `A beauty kit typically includes a curated selection of skincare and makeup essentials designed to enhance and simplify your beauty routine. With products ranging from moisturizers and serums to lipsticks and eyeshadows, beauty kits cater to various needs, making them perfect for travel or everyday use.`,
      },
      {
        id: 35,
        title: 'Erasers',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Erasers.png',
        desc: `Erasers remove pencil marks from paper, allowing for corrections and adjustments. They come in various forms, including rubber, vinyl, and kneaded, each designed for different types of erasing and precision.`,
      },
      {
        id: 36,
        title: 'Rice Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/8.png',
        desc: `Rice paper is a thin, translucent paper made from the pith of the rice plant or other plant fibers. It is commonly used in Asian cuisine for wrapping spring rolls and in art for delicate painting and calligraphy due to its unique texture and absorbency.`,
      },
      {
        id: 37,
        title: 'Different shades',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/6.png',
        desc: `It includes a curated selection of skincare and makeup essentials designed to enhance and simplify your beauty routine. With products ranging from moisturizers and serums to lipsticks and eyeshadows, beauty kits cater to various needs, making them perfect for travel or everyday use. `,
      },
      {
        id: 38,
        title: 'Greeting Cards',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/5.png',
        desc: `A greeting card conveys personal messages and well-wishes for special occasions such as birthdays, holidays, or anniversaries. It often includes a thoughtful note or sentiment, and can be decorated with designs, illustrations, or photos to add a personal touch. `,
      },
      {
        id: 39,
        title: 'Compact Kit',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/1.png',
        desc: `A compact kit is a portable beauty essential that typically includes a combination of powder foundation, blush, and sometimes eyeshadow, perfect for touch-ups on the go. `,
      },
      {
        id: 40,
        title: 'Sticker',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/12.png',
        desc: `A sticker is a decorative or functional adhesive label that can be applied to various surfaces. It often features a design, message, or brand logo and can be used for decoration, labeling, or promotional purposes.`,
      },
      {
        id: 41,
        title: 'Highlighters',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Highlighters.png',
        desc: `Highlighters are used to emphasize important text or information by marking it with bright, translucent colors. They make key points stand out, helping with note-taking, studying, and organizing documents. `,
      },
      {
        id: 42,
        title: 'Nivea (Dry Comfort)',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/Cosmetics/18.png',
        desc: `NIVEA Dry Comfort is a reliable deodorant that provides long-lasting protection against sweat and body odor while keeping the skin feeling dry and fresh. Formulated with a special combination of ingredients, it ensures effective moisture control and comfort throughout the day, making it suitable for all skin types. `,
      },
      {
        id: 43,
        title: 'FA Brighting & care',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/Cosmetics/25.png',
        desc: `FA Brightening & Care offers a range of body care products designed to enhance skin radiance while providing effective odor protection. This line features delightful fragrances, including Caribbean Lemon, Aqua, Mystic Moment, Invisible Power Soft Freshness and Men Invisible Power, Pink Passion Sport variant offers an energizing scent perfect for active lifestyles.`,
      },
      {
        id: 44,
        title: 'Shirts & Shorts(kids boy)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/Kids.png',
        desc: `Shirts and shorts for boys come in a variety of fun colors and patterns, making them perfect for both casual outings and active play.`,
      },
      {
        id:45,
        title: 'Tag Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/9.png',
        desc: `Tag paper is a thick, sturdy paper often used for creating tags, labels, or tickets. It has a rougher texture compared to standard paper, making it durable and suitable for applications that require strength and rigidity.`,
      },
      {
        id: 46,
        title: 'Enchanteur Talcum Powder',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Cosmetics/12.png',
        desc: `Enchanteur Talcum Powder offers a luxurious range of fragrances designed to keep you feeling fresh and confident throughout the day. Each variant captures a unique essence: Enticing, Alluring, Charming, Romantic, Stunning and Desire. All varieties are formulated to absorb moisture and keep your skin soft and smooth. `,
      },
      {
        id: 47,
        title: 'Notecards',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Notecards.png',
        desc: `Notecards are small, versatile cards used for writing brief notes, messages, or reminders. They are often used for thank-you notes, personal messages, or as a tool for organizing thoughts and information. `,
      },
      {
        id: 48,
        title: 'Mascara',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/Mascara.png',
        desc: `Mascara enhances the eyelashes by adding length, volume, and definition. It comes in various formulas and brushes, allowing you to achieve everything from a natural, everyday look to dramatic, full lashes.`,
      },
      {
        id: 49,
        title: 'Tissue Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/10.png',
        desc: `Tissue paper is a thin, lightweight paper often used for wrapping delicate items, gift packaging, and crafts. It comes in various colors and can be used to add a decorative touch or provide cushioning.`,
      },
      {
        id: 50,
        title: 'Notepads',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Notepads.png',
        desc: `A notepad is a pad of paper used for jotting down notes, ideas, or reminders. It comes in various sizes and formats, providing a convenient way to capture information quickly and keep it organized. `,
      },
      {
        id: 51,
        title: 'Moisturizer',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/Moisturizer.png',
        desc: `Moisturizer hydrates and nourishes the skin, helping to maintain its softness and elasticity. It creates a protective barrier to lock in moisture, preventing dryness and keeping your skin smooth and healthy.`,
      },
      {
        id: 52,
        title: 'Cover Stock',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/5.png',
        desc: `Cover stock is a heavier, thicker paper used for making covers for books, business cards, and other printed materials that require added durability. It offers a sturdy and professional finish, often with a smooth or textured surface.`,
      },
      {
        id: 53,
        title: 'Primer',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Primer.png',
        desc: `Primer creates a smooth base for makeup application by filling in pores and fine lines. It helps to enhance the longevity of your makeup, ensuring a more even and flawless finish while also controlling shine and improving overall skin texture.`,
      },
      {
        id: 54,
        title: 'Pencils',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Pencils.png',
        desc: `Pencils are writing instruments with a graphite core encased in wood or a mechanical casing. They are versatile for writing, drawing, and sketching, and can be easily erased for corrections. `,
      },
      {
        id: 55,
        title: 'Hair Removal Cream',
        category: 'Cosmetics',
        price: 2200,
        img:
        './assets/cosmetics/11.png',
        desc: `Hair removal cream provides a quick and painless method for eliminating unwanted hair, leaving the skin smooth and hair-free within minutes. `,
      },
      {
        id:56,
        title: 'Tracing Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/3.png',
        desc: `Tracing paper is a translucent paper used to trace or copy images and designs. Its see-through quality allows for easy transfer of details from one surface to another, making it a valuable tool for artists, designers, and architects.`,
      },
      {
        id: 57,
        title: 'Post Cards',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Postcards.png',
        desc: `Postcards are single, flat cards used to send brief messages or greetings without an envelope. They typically feature an image or design on one side and a space for a message and address on the other, making them a popular choice for travel updates and personal notes.`,
      },
      {
        id: 58,
        title: 'Setting Spray',
        category: 'Cosmetics',
        price: 2200,
        img:
          './assets/cosmetics/Setting Spray.png',
        desc: `Setting spray locks in makeup and helps it last longer by creating a protective layer over your face. It also helps to reduce shine and keep your makeup looking fresh throughout the day. `,
      },
      {
        id: 59,
        title: 'Pens',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Pens.png',
        desc: `Pens are writing instruments that use ink to produce marks on paper. Available in various types, including ballpoint, rollerball, and fountain, they offer different writing experiences and are essential tools for everyday writing tasks and creative endeavors. `,
      },
      {
        id: 60,
        title: 'Cotton Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/2.png',
        desc: `Cotton paper is made from cotton fibers, providing a high-quality, textured surface that is durable and absorbent. It is often used for stationery, high-end printing, and art projects, offering a premium feel and professional finish.`,
      },
      {
        id: 61,
        title: 'Board Pens',
        category: 'Stationary',
        price: 1000,
        img: './assets/Stationery/board pens.png',
        desc: `Board pens, or whiteboard markers, are used to write or draw on whiteboards or glass boards. They come in various colors and tip sizes and are designed to be easily erasable, making them ideal for presentations, brainstorming sessions, and teaching.`,
      },
      
      {
        id: 62,
        title: 'Waxed Paper',
        category: 'Paper',
        price: 1000,
        img: './assets/Paper/6.png',
        desc: `Waxed paper is coated with a thin layer of wax to make it moisture-resistant and non-stick. It is commonly used in food preparation and storage, as well as for wrapping and lining surfaces to prevent sticking and protect from spills.`,
      },
      {
        id: 63,
        title: 'Personalized Stationery',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/7.png',
        desc: `Personalized stationery is custom-designed paper and envelopes that reflect an individual's style or branding. It adds a unique and professional touch to correspondence, making letters, invitations, and notes stand out with a personal or business flair.`,
      },
      {
        id: 64,
        title: 'Pin',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/pin.png',
        desc: `A pin is a small, often decorative item used for fastening or attaching papers, fabrics, or accessories. It can be practical, like a pushpin or safety pin, or decorative, such as enamel pins used for personal expression or branding. `,
      },
      {
        id: 65,
        title: 'Fork',
        category: 'Disposable',
        price: 1200,
        img:
          './assets/Disposable/3.png',
        desc: `Disposable fork are single-use utensils made from materials like plastic or biodegradable substances, designed for convenience and hygiene. They are ideal for events, takeout meals, and situations where reusable options aren't practical.`,
      }, 
      {
        id: 66,
        title: 'Rubber',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Rubber.png',
        desc: `Rubber is a flexible, durable material used in various products like tires, erasers, and seals. It provides cushioning and grip, making it valuable for both practical applications and industrial uses. `,
      },
      {
        id: 67,
        title: 'Stapler',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Stapler.png',
        desc: `A stapler fastens together sheets of paper by driving metal staples through them. It's a common office tool used for organizing documents, ensuring they stay together securely. `,
      },
      {
        id: 68,
        title: 'Sticky Notes',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Sticky notes.png',
        desc: `Sticky notes are small, self-adhesive papers used for jotting down reminders, to-do lists, or messages. Their removable adhesive allows them to be easily placed and repositioned on various surfaces, making them convenient for quick notes and organization. `,
      },
      {
        id: 69,
        title: 'Bottles',
        category: 'Disposable',
        price: 1200,
        img:
          './assets/Disposable/18.png',
        desc: `Bottles are containers used to hold and store liquids, such as beverages, medicines, or cleaning solutions. They come in various materials like glass, plastic, or metal, and are designed to be airtight and leak-proof for optimal preservation and convenience.`,
      }, 
      {
        id: 70,
        title: 'Taps',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Taps.png',
        desc: `Taps, often referred to as faucets, are devices used to control the flow of water from a pipe. They come in various styles and types, including single and double handles, and are essential for directing water in sinks, bathtubs, and other plumbing fixtures. `,
      },
      {
        id: 71,
        title: 'Writing Paper',
        category: 'Stationary',
        price: 2500,
        img:
          './assets/Stationery/Writing Paper.png',
        desc: `Writing paper is used for crafting handwritten letters, notes, and documents. It typically features a smooth surface for easy writing and can come in various sizes and weights, often designed to be compatible with different types of pens and inks. `,
      },
      {
        id: 72,
        title: 'Casual Pant(men)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/11.png',
        desc: `Available in various styles, including chinos, joggers, and cargo pants, they can be paired effortlessly with t-shirts, polos, or casual shirts for a laid-back yet polished look.`,
      },
      {
        id: 73,
        title: 'Night Dress(girls)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/night dress.png',
        desc: `Night dresses are designed for comfort and ease, typically made from soft, breathable fabrics like cotton. They come in playful designs and bright colors, ensuring a cozy and enjoyable bedtime experience.`,
      },
      {
        id: 74,
        title: 'Saree(women)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/18.png',
        desc: `Available in a myriad of fabrics, colors, and designs, sarees can be styled for various occasions, from everyday wear to festive celebrations, making them a timeless and versatile choice in women's fashion.`,
      },
      {
        id: 75,
        title: 'Ice-cream cup',
        category: 'Disposable',
        price: 1200,
        img:
          './assets/Disposable/6.png',
        desc: `Disposable ice cream cups are convenient, single-use containers designed for serving ice cream, gelato, and other frozen desserts, making them ideal for parties, events, and takeout.`,
      }, 
      {
        id: 76,
        title: 'Frock(kids)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/24.png',
        desc: `A frock is a type of dress, usually worn by young girls or women, that features a fitted bodice and a flared skirt. It is often chosen for its classic, feminine look and can range from casual to formal styles depending on the occasion.`,
      },
      {
        id: 77,
        title: 'Pent Coat(kid boy)',
        category: 'Garments',
        price: 1150,
        img:
          './assets/Garments/6.png',
        desc: `A pent coat is a stylish, tailored coat that combines the features of a classic overcoat with a modern, fitted design. It typically has a sleek silhouette and can be worn for both formal and casual occasions, providing warmth and elegance.`,
      },
      {
        id: 78,
        title: 'Night dress(kids girl)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/13.png',
        desc: `Kid's Night dresses are designed for comfort and ease, typically made from soft, breathable fabrics like cotton. They come in playful designs and bright colors, ensuring a cozy and enjoyable bedtime experience.`,
      },
      {
        id: 79,
        title: 'Bowl',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/2.png',
        desc: `Disposable bowls are versatile, single-use containers ideal for serving snacks, salads, soups, and desserts, making them perfect for parties, picnics, and takeout.`,
      },
      {
        id: 80,
        title: 'Night dress(kids boy)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/14.png',
        desc: `Kid's Night dresses are designed for comfort and ease, typically made from soft, breathable fabrics like cotton. They come in playful designs and bright colors, ensuring a cozy and enjoyable bedtime experience.`,
      },
      {
        id: 81,
        title: 'Tea Cup',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/5.png',
        desc: `Available in various materials, including porcelain, ceramic, and glass, tea cups come in diverse designs and styles, making them perfect for both casual sipping and formal tea settings.`,
      },
      {
        id: 82,
        title: 'Shirt & Trouser(Women)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/15.png',
        desc: `A shirt and trouser combination offers a classic and polished look suitable for various occasions, from casual outings to formal events. Available in a range of styles, fabrics, and colors, this versatile pairing allows for easy mixing and matching, ensuring comfort and style for any setting.`,
      },
      {
        id: 83,
        title: 'Maxi(women)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/16.png',
        desc: `A maxi dress is a long, flowing garment that typically reaches the ankles or floor, offering a comfortable yet stylish option for casual outings, beach days, or formal events.`,
      },
      {
        id: 84,
        title: 'Baking cake cup',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/7.png',
        desc: `Baking cake cups are convenient, single-use containers designed for baking individual portions of cakes, muffins, or cupcakes, making them perfect for parties, celebrations, or personal treats.`,
      },
      {
        id: 85,
        title: 'Lehnga(women)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/19.png',
        desc: `A lehnga is a traditional Indian skirt that is intricately designed and often paired with a matching blouse (choli) and a dupatta, making it a popular choice for festive occasions and weddings.`,
      },
      {
        id: 86,
        title: 'Box',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/9.png',
        desc: `A box is a versatile container used for storage, organization, or packaging, available in various sizes, shapes, and materials, including cardboard, plastic, and wood.`,
      },
      {
        id: 87,
        title: 'Pants(men)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/pants.png',
        desc: `Men's pants come in a variety of styles, including formal trousers, chinos, jeans, and cargo pants, offering versatility for different occasions, from casual outings to professional settings.`,
      },
      {
        id: 88,
        title: 'Glass',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/14.png',
        desc: `Available in various sizes and designs, water glasses can enhance the aesthetic of a table while providing a practical and refreshing way to stay hydrated throughout the day.`,
      },
      {
        id: 89,
        title: 'Box',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/10.png',
        desc: `Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce`,
      },
      {
        id: 90,
        title: 'Kurta(men)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/Kurta.png',
        desc: `Available in a variety of styles, colors, and designs, kurtas can be paired with trousers, jeans, or dhotis, offering a versatile and stylish option that reflects cultural heritage.`,
      },
      {
        id: 91,
        title: 'Paper plates',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/15.png',
        desc: `Paper plates are disposable, lightweight dining plates made from paper or cardboard, designed for convenience during meals, parties, and outdoor events.`,
      },
      {
        id: 92,
        title: 'Box',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/11.png',
        desc: `Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce.`,
      },
      {
        id: 93,
        title: 'Lady pants(girls)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/Lady Pant.png',
        desc: `Designed for comfort and versatility, these pants are available in different fabrics, colors, and cuts, allowing women to effortlessly transition from casual to formal looks.`,
      },
      {
        id: 94,
        title: 'Drink Glass',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/Glass (2).png',
        desc: `Available in various sizes and designs, drink glasses can enhance the aesthetic of a table while providing a practical and refreshing way to stay hydrated throughout the day.`,
      },
      {
        id: 95,
        title: 'Dove Soap',
        category: 'Cosmetics',
        price: 1700,
        img:
         './assets/cosmetics/Cosmetics/8.png',
        desc: `Dove soap offers a variety of gentle cleansing options designed to nourish and care for the skin. The Pink variant features a soothing formula with a light floral scent, ideal for sensitive skin, while the Beauty Cream Bar combines rich moisturizing cream with a classic clean, providing hydration and a soft, smooth feel. `,
      },
      {
        id: 96,
        title: 'Shirts(kids boy)',
        category: 'Garments',
        price: 1500,
        img:
          './assets/Garments/shirts.png',
        desc: `Designed for comfort and durability, these shirts are made from breathable fabrics and often feature fun prints, ensuring that young boys can play and move freely while looking stylish.`,
      },
      {
        id: 97,
        title: 'Baking tray',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/21.png',
        desc: `A baking tray is a flat, rectangular kitchen tool used for baking a variety of foods, including cookies, pastries, and roasted vegetables, and is typically made from metal or silicone.`,
      },
      {
        id: 98,
        title: 'Paper box',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/13.png',
        desc: `Paper Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce`,
      },
      {
        id: 99,
        title: 'Containers',
        category: 'Disposable',
        price: 1000,
        img: './assets/Disposable/20.png',
        desc: `Available in various sizes and designs, these containers provide secure closures to prevent spills and maintain freshness, making them ideal for both hot and cold foods. Their lightweight and recyclable options also contribute to easy cleanup and sustainability, catering to a wide range of food storage needs.`,
      },
     
      
  ]
  export default menu
  