import React from 'react'
import Job from '../Components/JobCard/JobCard'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'


const Career = () => {
  return (
    <Layout>
      <div>
        <img src='./assets/Job.png' alt='Career'/>
      </div>
      <div className='container-fluid'>
    <div className='row'>

      <nav className='breadcrumb' aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link className='crumb' to="/">Home</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Career</li>
  </ol>
</nav>
    </div>
</div>
        <div className='container mt-3' >
            <div className='row'>
                <div className='col-md-12'>

                     <Job/>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default Career
