import React, { useRef, useState } from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import {   ToastContainer ,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const contactUser= (e) => {
        
    e.preventDefault();
    let userData = { email, message}
    
    if (userData.email.length===0 || userData.message.length===0 )
     {
        // alert("error");
        toast.error("Please complete the form!", {
            position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
          });
       
    }
    else{
        emailjs
        .sendForm(
            'service_2jtsodr',
            'template_igvld15',
            form.current, {
                publicKey: '1fDJ9s29871eAaXGU',
            })
            .then(
                () => {
                  console.log('SUCCESS!');
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );
              toast.success("Data send!", {
                position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
              });
              // Reset the form after successful submission
    //  form.reset();
    setEmail('')
    setMessage('')
            }

  }
  return (
    <Layout>
          <div>
                      <img src='./assets/2.png' alt='Contact us'/>        
        </div>
<div className='container-fluid'>
    <div className='row'>

      <nav className='breadcrumb' aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link className='crumb' to="/">Home</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
  </ol>
</nav>
    </div>
</div>
     <section className="text-gray-600 body-font relative">
  <div className="absolute inset-0 bg-gray-300">
 <iframe title="Your Unique and Descriptive Title" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.995463072565!2d114.17634389999999!3d22.2781617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404005bb3be915f%3A0x6989880e0431072a!2sEasey%20Commercial%20Building%2C%20253-261%20Hennessy%20Rd%2C%20Wan%20Chai%2C%20Hong%20Kong!5e0!3m2!1sen!2s!4v1727687442598!5m2!1sen!2s"  style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

  </div>
  <div className="container px-5 py-24 mx-auto flex">
    <div className="lg:w-1/2 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
      <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Get In Touch</h2>
      <p className="leading-relaxed mb-5 text-gray-600">If you have a question about BAO ZONG HAO LIMITED®  or if you have a comment or idea you'd like to share — we'd love to hear from you! Please contact us through the form below</p>
      <form className="contact-form  " ref={form} onSubmit={contactUser}>
      <div className="relative mb-4">
        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required/>
      </div>
      <div className="relative mb-4">
        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" defaultValue={""} value={message}  onChange={(e) => setMessage(e.target.value)} placeholder="Enter your message" required />
      </div>
      <button type="submit" value="Send" className="text-white  btn  border-0 py-2 px-6 focus:outline-none  rounded text-lg">Send Message</button>
      </form>
    </div>
  </div>
</section>

<div className="container my-5">
        <div className="row">
          {/* Address Section */}
          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <div className="contact-box">
              <h3 className="mb-1 fs-5">Our Address</h3>
              <div className="info-item ">
                {/* <i className="bi bi-geo-alt-fill icon "></i> */}
                Office: 1902, Easay comm, BLOG, 253-261 Hennessy Road, wanchai HK
              </div>
            </div>
          </div>

          {/* Vertical Line */}
          <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center ">
            <div className="vertical-line" ></div>
          </div>
          {/* Contact Info Section */}
          <div className="col-md-3 col-12 mb-4 mb-md-0">
            <div className="contact-box">
              <h3 className="mb-1 fs-5">Contact Info</h3>
              <div className="info-item">
                <i className="bi bi-phone-fill icon"></i>
                <Link to="tel:+923107770417" style={{ textDecoration: 'none', color: '#000' }}>+0086-134560012967</Link>
              </div>
            </div>
          </div>

          {/* Vertical Line */}
          <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center">
            <div className="vertical-line"></div>
          </div>
          {/* Email Section */}
          <div className="col-md-2 col-12">
            <div className="contact-box ">
              <h3 className="mb-1 fs-5">Email Us</h3>
              <div className="info-item">
                <i className="bi bi-envelope-fill icon"></i>
                <Link to="mailto:info@example.com" style={{ textDecoration: 'none', color: '#000' }}>sales.baozon@baozonghaolimited.com</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  )
}

export default Contact
